import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { SideSheet, Position } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

import InfoIcon from '@material-ui/icons/Info';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import qs from 'qs';
import Login from '../common/login';
const columns = [
    // { field: "delete", name: "حذف", options: { width: 60, filter: true, sort: false, } },
    //   { field: "date", name: "تاريخ الاضافة ", options: { filter: true, sort: false, } },
    // { field: "add_date", name: " ", options: { filter: true, sort: false, } },

    { field: "edit", name: "تعديل", options: { filter: true, sort: false, } },

    { field: "info", name: "التفاصيل", options: { filter: true, sort: false, } },
    { field: "phone", name: "رقم الهاتف", options: { filter: true, sort: false, } },

    { field: "name", name: "اسم البيج ", options: { filter: true, sort: false, } },

    { field: "id", name: "#", options: { width: 200, filter: true, sort: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],

            spin: false,
            serch: false,
            total: ''
        }


    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })
    DeleteCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `dashbord/spending/${id}?`, { headers: header })
            .then(response => {
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.Orders();
            })
            .catch(error => { console.log(error) })
    }
    componentDidMount() {

        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `dashbord/pages/all`, { headers: header })
            .then(res => {
                let arr = [];

                for (let i = 0; i < res.data.pages.length; i++) {
            
                    let obj = {
                        id: res.data.pages[i].id,
                        name: res.data.pages[i].name,
                        phone: res.data.pages[i].phone,
                        info: <div><Link to={`/Page?id=${res.data.pages[i].id}`} style={{ color: '#28a745', cursor: 'pointer' }} > <InfoIcon /></Link></div>,
                        //   edit: <div><Link to={`/Page?id=${res.data.pages[i].id}`} style={{ color: '#007bff', cursor: 'pointer' }} > <EditIcon /></Link></div>,
                        edit:             <Component initialState={{ isShown: false, nameUser: res.data.pages[i].name, phoneUser:  res.data.pages[i].phone }}>
                        {({ state, setState }) => (
                            <React.Fragment>
                                <SideSheet
                                    isShown={state.isShown}
                                    position={Position.LEFT}
                                    onCloseComplete={() => setState({ isShown: false })}
                                >
                                    <div margin={40}>
                                        <div id='titleAddUser' style={{ backgroundColor: '#000' }}> تعديل البيج</div>
                                        <div id='inputAdd2Continer' >
                                            <div id={'ContinerInPut'} >
                                                <label>اسم البيج</label>
                                                <input autoComplete='off' type='text' placeholder='اسم البيج' value={state.nameUser} id='InputTExtDash1'
                                                    onChange={(e) => setState({ nameUser: e.target.value })} />
                                            </div>
                                            <div id={'ContinerInPut'} >
                                                <label>رقم الهاتف</label>
                                                <input autoComplete='off' type='number' value={state.phoneUser}  placeholder='رقم الهاتف' id='InputTExtDash1'
                                                    onChange={(e) => setState({ phoneUser: e.target.value })} />
                                            </div>
 
                                        </div>
                                        <div id='btnAddContiner'>
                                            <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {
                                                axios.post(host + `dashbord/pages/edit/${res.data.pages[i].id}`,
                                                    qs.stringify({ name: state.nameUser, phone: state.phoneUser }), {
                                                    headers: {
                                                        "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                    }
                                                })
                                                    .then(response => {
                                                        toast('تمت الاضافة بنجاح', {
                                                            position: "bottom-center",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true
                                                        });

                                                    })
                                                    .catch(error => {
                                                        console.log(error.response.data.message)
                                                        this.setState({ spinerBtn: false })
                                                    })
                                                setState({ isShown: false })
                                            }}>تعديل</div>
                                        </div>

                                    </div>
                                </SideSheet>
                                <EditIcon  style={{ color: '#007bff', cursor: 'pointer' }} onClick={() => setState({ isShown: true })}> </EditIcon>
                            </React.Fragment>
                        )}
                    </Component>

                    };
                    arr.push(obj);
                }
                this.setState({
                    orders: arr, spin: false, total: 0
                });
            })
            .catch(error => {
            });
    }
    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='navDashContiner'>
                                    <Link to={'/SettingDash'} id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} > رجوع</Link>

                                    <Component initialState={{ isShown: false, nameUser: '', phoneUser: '', passwordUser: '', }}>
                                        {({ state, setState }) => (
                                            <React.Fragment>
                                                <SideSheet
                                                    isShown={state.isShown}
                                                    position={Position.LEFT}
                                                    onCloseComplete={() => setState({ isShown: false })}
                                                >
                                                    <div margin={40}>
                                                        <div id='titleAddUser' style={{ backgroundColor: '#000' }}>اضافة موظف</div>
                                                        <div id='inputAdd2Continer' >
                                                            <div id={'ContinerInPut'} >
                                                                <label>اسم البيج</label>
                                                                <input autoComplete='off' type='text' placeholder='اسم البيج' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ nameUser: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>رقم الهاتف</label>
                                                                <input autoComplete='off' type='number' placeholder='رقم الهاتف' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ phoneUser: e.target.value })} />
                                                            </div>

                                                        </div>
                                                        <div id='btnAddContiner'>
                                                            <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {
                                                                axios.post(host + `dashbord/pages/add`,
                                                                    qs.stringify({ name: state.nameUser, phone: state.phoneUser }), {
                                                                    headers: {
                                                                        "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        toast('تمت الاضافة بنجاح', {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });
                                                                        this.componentDidMount()
                                                                    })
                                                                    .catch(error => {
                                                                        console.log(error.response.data.message)
                                                                        this.setState({ spinerBtn: false })
                                                                    })
                                                                setState({ isShown: false })
                                                            }}>اضافة</div>
                                                        </div>

                                                    </div>
                                                </SideSheet>
                                                <div id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })}>اضافة بيج </div>
                                            </React.Fragment>
                                        )}
                                    </Component>

                                </div>


                                <div>
                                    <div className='DataTableContiner'>
                                        <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.orders} columns={columns} options={options} />
                                        </MuiThemeProvider>
                                    </div>

                                </div>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;