import React from 'react';
import Context from '../../assets/js/Context';
import host from '../../assets/js/Host';
import Component from "@reactions/component";
import { Tabs, Tab } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import qs from 'qs';
import { Spinner, SearchInput, Pane, Dialog } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoIcon from '@material-ui/icons/Info';
// import Component from "@reactions/component";
import CancelIcon from '@material-ui/icons/Cancel';
import Cookies from "universal-cookie";
import axios from 'axios';
import { Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import * as moment from "moment-timezone";
const cookies = new Cookies();
class Statistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            page: [],
            Data1: [],
            page1: [],
            Data2: [],
            page2: [],
            name: '',
            phone1: '',
            phone2: '',
            price: '',
            address: '',
            email: '',
            officename: '',
            officephone: '',
            idshipment: '',
            redirect: false,
            spinPage: true,
            activePage: 0,
            search: '',
            activetap: 'wating'
        }
        // this.socket();
    }
    componentDidMount(activePage, search, limit) {
        this.setState({ spinPage: true })
        // var officeId = localStorage.getItem('officeId')
        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `users/component/order/get/?status=0&search=${search ? search : ''}&limit=${limit ? limit : 20}&page=${activePage ? activePage : 1}`, { headers: header })
            .then(res => {
                // console.log(res.data.data.my_Orders);
                this.setState({
                    Data: res.data.data.my_Orders, spinPage: false,
                    page: res.data.data
                });
            })
            .catch(error => {
                console.log(error.response)
            })

        axios.get(host + `users/component/order/get/?status=1&search=${search ? search : ''}&limit=${limit ? limit : 20}&page=${activePage ? activePage : 1}`, { headers: header })
            .then(res => {
                console.log(res.data.data.my_Orders);
                this.setState({
                    Data1: res.data.data.my_Orders, spinPage: false,
                    page1: res.data.data
                });
            })
            .catch(error => {
                console.log(error.response)
            })
    }

    handlePageClick(pageNumber) {
        this.setState({ activePage: pageNumber.selected })
        this.componentDidMount(pageNumber.selected + 1)

    }

    cahngeStatus(id) {
        axios.put(host + `users/component/order/cancel/${id}`,
            qs.stringify({ }), {
                headers: { "Content-Type": "application/json", token: cookies.get("token") }
        }).then(response => {
            this.setState({ nameCate: '', BtnAdd: false })
            toast('تم الغاء الطلب', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            this.componentDidMount()
        }).catch(error => { console.log(error) })
    }
    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    return (
                        <div >

                            {!this.state.spinPage ?
                                <div id='tableAdminContiner' >
                                    <Paper className={'classesroot'} >
                                        <Tabs defaultActiveKey={this.state.activetap} id="uncontrolled-tab-example" style={{ backgroundColor: '#fff',paddingTop:50 }}>
                                            <Tab eventKey='wating' title="الطلبات" tabClassName='wating' onClick={() => this.setState({ activetap: 'wating' })}>
                                                <div id='headSerachLimit'>
                                                    <SearchInput placeholder="بحث" style={{ paddingRight: 30 }} onChange={e => {
                                                        this.componentDidMount(1, e.target.value)
                                                    }} />
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <label style={{ marginLeft: 10, marginTop: 10 }}>عدد الصفوف</label>
                                                        <select className='InputTExtDash111' style={{ height: 30, width: 60, paddingRight: 5, paddingLeft: 5, textAlign: 'left' }}
                                                            name='city' onChange={(e) => {
                                                                if (e.target.value !== 'limit') {
                                                                    this.componentDidMount(1, '', e.target.value)
                                                                }
                                                            }}>
                                                            <option value={20}>20</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                        <div className='InputTExtDash111NN' style={{ height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {this.state.page.totalItems}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='DataTableContiner' style={{ direction: 'rtl' }} >
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr style={{ textAlign: 'center' }}>
                                                                <th>#</th>
                                                                <th>الاسم</th>
                                                                <th>الهاتف</th>
                                                                <th>العنوان</th>
                                                                <th>السعر</th>
                                                                <th>الخصم</th>
                                                                <th>التاريخ</th>
                                                                <th>المواد</th>
                                                                <th>رفض</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.Data.map((data, i) =>
                                                                <tr key={i} style={{ textAlign: 'center' }}>
                                                                    <td>{data.id}</td>
                                                                    <td>{data.name}</td>
                                                                    <td>{data.phone}</td>
                                                                    <td>{data.address}</td>
                                                                    <td>{data.new_price}</td>
                                                                    <td>{data.discount}</td>
                                                                    <td>{moment(data.createdAt).tz('Asia/baghdad').format('YYYY/MM/DD LT')}</td>
                                                                    <td>
                                                                        <Component initialState={{ isShown: false }}>
                                                                            {({ state, setState }) => (
                                                                                <Pane>
                                                                                    <Dialog
                                                                                        isShown={state.isShown}
                                                                                        onCloseComplete={() => setState({ isShown: false })}
                                                                                        hasFooter={false}
                                                                                        hasHeader={false}
                                                                                    >
                                                                                        <div style={{ direction: 'rtl', textAlign: 'right' }}>
                                                                                            {this.state.spinerMod ? (<Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                                                                                                <Spinner />
                                                                                            </Pane>) : (
                                                                                                <Table striped bordered hover>
                                                                                                    <thead>
                                                                                                        <tr style={{ textAlign: 'center' }}>
                                                                                                            <th>الاسم</th>
                                                                                                            <th>العدد</th>
                                                                                                            <th>صوره</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        {data.c_orderItems.map((item, i) =>
                                                                                                            <tr key={i} style={{ textAlign: 'center' }}>
                                                                                                                <td>{item.components.name}</td>
                                                                                                                <td>{item.count}</td>
                                                                                                                <td> <img src={host + 'files/' + item.components.photo} alt='img' style={{ height: 40, width: 40, borderRadius: 5 }} /></td>
                                                                                                            </tr>
                                                                                                        )}
                                                                                                    </tbody>
                                                                                                </Table>
                                                                                            )}
                                                                                        </div>

                                                                                    </Dialog>
                                                                                    <InfoIcon style={{ cursor: 'pointer', color: '#77da5e' }}
                                                                                        onClick={() => { setState({ isShown: true }) }} />
                                                                                </Pane>
                                                                            )}
                                                                        </Component>,

                                                                    </td>
                                                                    <td>
                                                                        <Component initialState={{ isShown: false }}>
                                                                            {({ state, setState }) => (
                                                                                <Pane>
                                                                                    <Dialog
                                                                                        isShown={state.isShown}
                                                                                        title="رفض الطلب"
                                                                                        intent="danger"
                                                                                        onCloseComplete={() => setState({ isShown: false })}
                                                                                        confirmLabel="تاكيد"
                                                                                        cancelLabel="الغاء"
                                                                                        onConfirm={() => { this.cahngeStatus(data.id) }}
                                                                                    >
                                                                                        هل انت متاكد من عملية رفض الطلب
                                                                                </Dialog>
                                                                                    <CancelIcon style={{ color: '#d26a74', cursor: 'pointer' }} onClick={() => setState({ isShown: true })} />
                                                                                </Pane>
                                                                            )}
                                                                        </Component>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>

                                                </div>
                                                <div id='pagnationContiner'>
                                                    <ReactPaginate
                                                        previousLabel={'السابق'}
                                                        nextLabel={'التالي'}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={this.state.page.totalpages}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={8}
                                                        forcePage={this.state.activePage}
                                                        onPageChange={(page) => this.handlePageClick(page)}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                    />
                                                </div>
                                            </Tab>
                                            <Tab eventKey="cancel" title="طلبات تم الغاؤها " tabClassName='cancel' onClick={() => this.setState({ activetap: 'cancel' })}>
                                                <div id='headSerachLimit'>
                                                    <SearchInput placeholder="بحث" style={{ paddingRight: 30 }} onChange={e => {
                                                        this.componentDidMount(1, e.target.value)
                                                    }} />
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <label style={{ marginLeft: 10, marginTop: 10 }}>عدد الصفوف</label>
                                                        <select className='InputTExtDash111' style={{ height: 30, width: 60, paddingRight: 5, paddingLeft: 5, textAlign: 'left' }}
                                                            name='city' onChange={(e) => {
                                                                if (e.target.value !== 'limit') {
                                                                    this.componentDidMount(1, '', e.target.value)
                                                                }
                                                            }}>
                                                            <option value={20}>20</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                        <div className='InputTExtDash111NN' style={{ height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {this.state.page2.totalItems}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='DataTableContiner' style={{ direction: 'rtl' }} >
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr style={{ textAlign: 'center' }}>
                                                                <th>#</th>
                                                                <th>الاسم</th>
                                                                <th>الهاتف</th>
                                                                <th>العنوان</th>
                                                                <th>السعر</th>
                                                                <th>الخصم</th>
                                                                <th>التاريخ</th>
                                                                <th>المواد</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.Data1.map((data, i) =>
                                                                <tr key={i} style={{ textAlign: 'center' }}>
                                                                    <td>{data.id}</td>
                                                                    <td>{data.name}</td>
                                                                    <td>{data.phone}</td>
                                                                    <td>{data.address}</td>
                                                                    <td>{data.new_price}</td>
                                                                    <td>{data.discount}</td>
                                                                    <td>{moment(data.createdAt).tz('Asia/baghdad').format('YYYY/MM/DD LT')}</td>
                                                                    <td>
                                                                        <Component initialState={{ isShown: false }}>
                                                                            {({ state, setState }) => (
                                                                                <Pane>
                                                                                    <Dialog
                                                                                        isShown={state.isShown}
                                                                                        onCloseComplete={() => setState({ isShown: false })}
                                                                                        hasFooter={false}
                                                                                        hasHeader={false}
                                                                                    >
                                                                                        <div style={{ direction: 'rtl', textAlign: 'right' }}>
                                                                                            {this.state.spinerMod ? (<Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                                                                                                <Spinner />
                                                                                            </Pane>) : (
                                                                                                <Table striped bordered hover>
                                                                                                    <thead>
                                                                                                        <tr style={{ textAlign: 'center' }}>
                                                                                                            <th>الاسم</th>
                                                                                                            <th>العدد</th>
                                                                                                            <th>صوره</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        {data.c_orderItems.map((item, i) =>
                                                                                                            <tr key={i} style={{ textAlign: 'center' }}>
                                                                                                                <td>{item.components.name}</td>
                                                                                                                <td>{item.count}</td>
                                                                                                                <td> <img src={host + 'files/' + item.components.photo} alt='img' style={{ height: 40, width: 40, borderRadius: 5 }} /></td>
                                                                                                            </tr>
                                                                                                        )}
                                                                                                    </tbody>
                                                                                                </Table>
                                                                                            )}
                                                                                        </div>

                                                                                    </Dialog>
                                                                                    <InfoIcon style={{ cursor: 'pointer', color: '#77da5e' }}
                                                                                        onClick={() => { setState({ isShown: true }) }} />
                                                                                </Pane>
                                                                            )}
                                                                        </Component>,

                                                                    </td>

                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>

                                                </div>
                                                <div id='pagnationContiner'>
                                                    <ReactPaginate
                                                        previousLabel={'السابق'}
                                                        nextLabel={'التالي'}
                                                        breakLabel={'...'}
                                                        breakClassName={'break-me'}
                                                        pageCount={this.state.page2.totalpages}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={8}
                                                        forcePage={this.state.activePage}
                                                        onPageChange={(page) => this.handlePageClick(page)}
                                                        containerClassName={'pagination'}
                                                        subContainerClassName={'pages pagination'}
                                                        activeClassName={'active'}
                                                    />
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </Paper>
                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                                : <div style={{ width: '100%', height: 'calc(100vh - 100px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner />
                                </div>
                            }

                        </div>
                    )
                }}
            </Context.Consumer>
        )
    }
}
export default Statistics;