import React from 'react';
import Context from '../../assets/js/Context';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LibraryAdd from '@material-ui/icons/LibraryAdd';
import Component from "@reactions/component";
import { SideSheet, Pane, Position, FilePicker, Icon, Spinner, RadioGroup } from 'evergreen-ui';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import qs from 'qs';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { withRouter } from 'react-router-dom'
const cookies = new Cookies();

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    AppBar: {
        backgroundColor: '#000',
    },
    Col: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
    },
    icons: {
        color: '#fff',
        marginRight: 10,
        cursor: 'pointer',
        '&:hover': {
            color: '#f8c429!important',
            transitionDuration: '.3s',
            transitionTimingFunction: 'linear',
        },
    },
    UsersHead: {
        width: '95%',
        height: 65,
        display: 'flex',
        marginRight: '2.5%',
        marginLeft: '2.5%',
        justifyContent: 'space-between',
        alignItems: 'center',
    }

})
class DenseAppBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            nameCate: '',
            image: [],
            spinerBtn: false,
            nameItem: '',
            priceItem: '',
            CountItem: '',
            nameUser: '',
            phoneUser: '',
            passwordUser: '',
            nameُExpenses: '',
            amountExpenses: '',
            options: [
                { label: 'نعم', value: 'نعم' },
                { label: 'كلا', value: 'كلا' },
            ],
            value: 'نعم',
        }
    }
   
    AddUser() {
        axios.post(host + `users/auth/register`,
            qs.stringify({ name: this.state.nameUser, password: this.state.passwordUser, phone: this.state.phoneUser }), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
            }
        })
            .then(response => {
                window.location.reload();
                this.setState({ spinerBtn: false })

            })
            .catch(error => {
                console.log(error.response.data.message)
                this.setState({ spinerBtn: false })
            })
    }
    AddExpenses() {
        axios.post(host + `dashbord/spending/add`,
            qs.stringify({ name: this.state.nameُExpenses, price: this.state.amountExpenses }), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
            }
        })
            .then(response => {
                window.location.reload();
                this.setState({ spinerBtn: false })

            })
            .catch(error => {
                console.log(error.response.data.message)
                this.setState({ spinerBtn: false })
            })
    }
    AddItem() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", this.state.nameItem);
        formData.append("count", this.state.CountItem);
        formData.append("price", this.state.priceItem);
        formData.append("hasCount", this.state.value === 'نعم' ? true : false);
        formData.append("category_id", this.props.match.params.id);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `dashbord/item`, method: "POST", data: formData, headers: header })
            .then(response => {
                window.location.reload();
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    render() {
        const { classes } = this.props;
        return (
            <Context.Consumer>
                {ctx => {
                    return (
                        <div className={classes.root}>

                            <AppBar position="static" className={classes.AppBar}>

                                {this.props.page === 'user' ? (
                                    <div className={classes.UsersHead}>
                                        <div style={{ display: 'flex' }}>
                                            <div>{this.props.phoneUser}</div>
                                            <PhoneIcon style={{ marginLeft: 10 }} />
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div>{this.props.nameUser}</div>
                                            <PersonIcon style={{ marginLeft: 10 }} />
                                        </div>
                                    </div>
                                ) : (<Toolbar variant="dense">

                                    <Component initialState={{ isShown: false }}>
                                        {({ state, setState }) => (
                                            <React.Fragment>
                                                <div id='AppbarContiner'>
                                                    {this.props.page === 'customerOrder' ? (
                                                        <div id='SpaceAppPar'>

                                                            <Link to='/CustomersDash'>
                                                                <Icon icon="circle-arrow-left" size={30} color="#fff"
                                                                    style={{ cursor: 'pointer' }} />
                                                            </Link>

                                                        </div>) : this.props.page === 'Items' ? (


                                                            <Link to='/CategoryDash'>
                                                                <Icon icon="circle-arrow-left" size={30} color="#fff"
                                                                    style={{ cursor: 'pointer' }} />
                                                            </Link>
                                                        ) : null}

                                                    <SideSheet
                                                        position={Position.LEFT}
                                                        isShown={state.isShown}
                                                        onCloseComplete={() => setState({ isShown: false })}
                                                    >
                                                        <Pane margin={40}>

                                                            <React.Fragment  >

                                                                {/* ////////////////////// category ////////////////////// */}
                                                                {this.props.page === 'category' ? (
                                                                    <div>
                                                                        <div id='titleAddAppBar'>اضافة قسم</div>
                                                                        <div id='inputAddContiner'>
                                                                            <div id={'ContinerInPut'} >
                                                                                <label>اسم القسم</label>
                                                                                <input autoComplete='off' type='text' placeholder='اسم القسم' id='InputTExtDash1'
                                                                                    onChange={(e) => this.setState({ nameCate: e.target.value })} />
                                                                            </div>
                                                                        </div>
                                                                        <div id='btnAddItemContiner'>
                                                                            {!this.state.spinerBtn ? (
                                                                                <div id='btnAddItem' onClick={() => {
                                                                                    this.setState({ spinerBtn: true })
                                                                                    this.AddCate()
                                                                                }}>اضافه</div>

                                                                            ) : (
                                                                                    <div id='btnAddItem'><Spinner size={24} /></div>

                                                                                )}
                                                                        </div>

                                                                    </div>
                                                                ) : null}
                                                                {/* ////////////////////// AddUser ////////////////////// */}
                                                                {this.props.page === 'Setting' ? (
                                                                    <div>
                                                                        <div id='titleAddAppBar'>اضافة مستخدم</div>
                                                                        <div id='inputAddContiner'>
                                                                            <div id={'ContinerInPut'} >
                                                                                <label>اسم المستخدم</label>
                                                                                <input autoComplete='off' type='text' placeholder='اسم المستخدم' id='InputTExtDash1'
                                                                                    onChange={(e) => this.setState({ nameUser: e.target.value })} />
                                                                            </div>
                                                                            <div id={'ContinerInPut'} >
                                                                                <label>رقم الهاتف</label>
                                                                                <input autoComplete='off' type='number' placeholder='رقم الهاتف' id='InputTExtDash1'
                                                                                    onChange={(e) => this.setState({ phoneUser: e.target.value })} />
                                                                            </div>
                                                                            <div id={'ContinerInPut'} >
                                                                                <label>كلمه المرور</label>
                                                                                <input autoComplete='off' type='text' placeholder='كلمه المرور' id='InputTExtDash1'
                                                                                    onChange={(e) => this.setState({ passwordUser: e.target.value })} />
                                                                            </div>
                                                                        </div>
                                                                        <div id='btnAddItemContiner'>
                                                                            {!this.state.spinerBtn ? (
                                                                                <div id='btnAddItem' onClick={() => {
                                                                                    this.setState({ spinerBtn: true })
                                                                                    this.AddUser()
                                                                                }}>اضافه</div>

                                                                            ) : (
                                                                                    <div id='btnAddItem'><Spinner size={24} /></div>

                                                                                )}
                                                                        </div>

                                                                    </div>
                                                                ) : null}
                                                                {/* ////////////////////// Items ////////////////////// */}
                                                                {this.props.page === 'Items' ? (
                                                                    <div>
                                                                        <div id='titleAddAppBar'>اضافة مادة</div>
                                                                        <div id='inputAddContiner'>
                                                                            <div id={'ContinerInPut'} >
                                                                                <label>اسم المادة </label>
                                                                                <input autoComplete='off' type='text' placeholder='اسم المادة ' id='InputTExtDash1'
                                                                                    onChange={(e) => this.setState({ nameItem: e.target.value })} />
                                                                            </div>
                                                                            <div id={'ContinerInPut'} >
                                                                                <label>السعر </label>
                                                                                <input autoComplete='off' type='number' placeholder='السعر ' id='InputTExtDash1'
                                                                                    onChange={(e) => this.setState({ priceItem: e.target.value })} />
                                                                            </div>
                                                                            <div id={'ContinerInPut'} >
                                                                                <label>العدد </label>
                                                                                <input autoComplete='off' type='number' placeholder='العدد ' id='InputTExtDash1'
                                                                                    onChange={(e) => this.setState({ CountItem: e.target.value })} />
                                                                            </div>
                                                                            <div id={'ContinerInPut'}>
                                                                                <label>اختر صورة</label>
                                                                                <FilePicker multiple id='InputTExtDash2' onChange={file => { this.setState({ image: file }) }} />
                                                                            </div>
                                                                            <div style={{ width: '100%', direction: 'rtl' }}>
                                                                                <RadioGroup id='RadioAppbar'
                                                                                    label=" العدد قابل للنفاذ ؟"
                                                                                    value={this.state.value}
                                                                                    options={this.state.options}
                                                                                    onChange={value => this.setState({ value })}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div id='btnAddItemContiner'>
                                                                            {!this.state.spinerBtn ? (
                                                                                <div id='btnAddItem' onClick={() => {
                                                                                    this.setState({ spinerBtn: true })
                                                                                    this.AddItem()
                                                                                }}>اضافه</div>

                                                                            ) : (
                                                                                    <div id='btnAddItem'><Spinner size={24} /></div>

                                                                                )}


                                                                        </div>

                                                                    </div>
                                                                ) : null}
                                                                {/* ////////////////////// AddExpenses////////////////////// */}
                                                                {this.props.page === 'Expenses' ? (
                                                                    <div>
                                                                        <div id='titleAddAppBar'>اضافة مصروف</div>
                                                                        <div id='inputAddContiner'>
                                                                            <div id={'ContinerInPut'} >
                                                                                <label>اسم المصروف</label>
                                                                                <input autoComplete='off' type='text' placeholder='اسم المصروف' id='InputTExtDash1'
                                                                                    onChange={(e) => this.setState({ nameُExpenses: e.target.value })} />
                                                                            </div>
                                                                            <div id={'ContinerInPut'} >
                                                                                <label>المبلغ</label>
                                                                                <input autoComplete='off' type='number' placeholder='المبلغ' id='InputTExtDash1'
                                                                                    onChange={(e) => this.setState({ amountExpenses: e.target.value })} />
                                                                            </div>
                                                                        </div>
                                                                        <div id='btnAddItemContiner'>
                                                                            {!this.state.spinerBtn ? (
                                                                                <div id='btnAddItem' onClick={() => {
                                                                                    this.setState({ spinerBtn: true })
                                                                                    this.AddExpenses()
                                                                                }}>اضافه</div>

                                                                            ) : (
                                                                                    <div id='btnAddItem'><Spinner size={24} /></div>

                                                                                )}
                                                                        </div>

                                                                    </div>
                                                                ) : null}

                                                            </React.Fragment  >
                                                        </Pane>
                                                    </SideSheet>
                                                    {(this.props.page === 'category' || this.props.page === 'Items' || this.props.page === 'Setting' || this.props.page === 'Expenses') ? (
                                                        <LibraryAdd className={classes.icons} onClick={() => setState({ isShown: true })} />
                                                    ) : null}

                                                </div>

                                            </React.Fragment>
                                        )}
                                    </Component>


                                </Toolbar>)}
                            </AppBar>
                        </div>
                    );
                }}
            </Context.Consumer>
        )
    }

}
DenseAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
}
export default withRouter(withStyles(styles)(DenseAppBar))
