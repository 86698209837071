import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import Component from "@reactions/component";
import { Pane, Dialog, Spinner, SideSheet, Position, Icon } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form, Image, Button, Table} from 'react-bootstrap';
import CategoryIcon from '@material-ui/icons/Category';

// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import DoneIcon from '@material-ui/icons/DoneAll';
import Clear from '@material-ui/icons/Clear';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MoneyOff from '@material-ui/icons/MoneyOff';


import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Login from '../common/login';
import * as moment from "moment-timezone";
const columns = [

    { field: "edit", name: "تعديل السعر", options: { width: 100, filter: false, sort: false, search: false } },
    { field: "items", name: "المواد", options: { width: 100, filter: false, sort: false, search: false } },
   
    { field: "createdAt", name: "تاريخ", options: { filter: true, sort: false, search: false } },
    { field: "price", name: "السعر", options: { filter: true, sort: false, search: false } },
    { field: "government", name: "المحافظة", options: { filter: false, sort: false, search: false } },
    { field: "phone", name: "هاتف الزبون", options: { filter: false, search: false } },
    { field: "user_name", name: "البيج", options: { filter: false, search: false } },

    { field: "id", name: "#", options: { filter: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,

    print: false,
    filter: false,
    download: false,
    search: true,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    },

}
const cookies = new Cookies();

class fromOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            Category: [],
            spin: false,
            totalCount: '',
            nameItem: '',
            priceItem: '',
            image: [],
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },
            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })
    onChangeD(value) {
        let count = document.getElementById(value).value;
        if (count > 0) {
            let newValue = Number(document.getElementById(value).value) - 1;
            document.getElementById(value).value = newValue
            // let re = ((item.count - count + 1) + 1)
            // console.log(re);
            // document.getElementById(text).textContent = `الكمية المتبقية ${re}`

        }

    }
    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/form/orders/all?status=3`, { headers: header })
            .then(res => {
                let arr = [];

                for (let i = 0; i < res.data.data.orders.length; i++) {
                    let obj = {
                        id: res.data.data.orders[i].id,

                        user_name: res.data.data.orders[i].user.name,

                        phone: res.data.data.orders[i].phone,
                        government: res.data.data.orders[i].government,

                        price: res.data.data.orders[i].price,

                        createdAt: moment(res.data.data.orders[i].createdAt).tz('Asia/baghdad').format('YYYY/MM/DD'),

                        items: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="مواد الطلب"
                                        intent="success"
                                        hasFooter={false}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="انجاح"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            //       this.deleverdCate(res.data.data.orders[i].id)
                                        }}
                                    >
                                        <div>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr style={{textAlign:'right'}}>


                                                        <th >العدد</th>
                                                        <th>اسم المنتج</th>

                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    {res.data.data.orders[i].items.map((item, i) =>
                                                        <tr key={i} style={{textAlign:'right'}}>
                                                            <td>{item.count}</td>
                                                            <td>{item.name}</td>


                                                        </tr>
                                                    )}


                                                </tbody>
                                            </Table>
                                        </div>
                                    </Dialog>
                                    <CategoryIcon style={{ cursor: 'pointer', color: '#ffc107' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                        edit: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title='تعديل السعر'
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="تعديل"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            // console.log(state.img);
                                            this.EditItem(res.data.data.orders[i].id)
                                            setState({ isShown: false })
                                        }}
                                    >
                                        <div>
                                            <div id='inputAddContiner'>
                                                <div id={'ContinerInPut'} >
                                                    <label>السعر </label>
                                                    <input autoComplete='off' type='number' placeholder='السعر ' id='InputTExtDash1'
                                                        onChange={(e) => this.setState({ priceItem: e.target.value })} />
                                                </div>
                                            </div>

                                        </div>
                                    </Dialog>
                                    <MoneyOff style={{ cursor: 'pointer', color: '#4752d1' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                   

                    };
                    arr.push(obj);
                }
                this.setState({
                    Category: arr, spin: true
                });
            })
            .catch(error => { console.log(error.response) })

    }
    DeleteCate(id) {

        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `users/all/ordrers/${id}`, { headers: header })
            .then(response => {

                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }

    deleverdCate(id) {


        axios.post(host + `dashbord/form/orders/deleverd`, { orders_id: id.toString() })
            .then(response => {

                toast('تم انجاح الطلب ', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    EditItem(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("price", this.state.priceItem);
        axios({ url: host + `dashbord/form/orders/edit/${id}`, method: "PUT", data: formData, headers: header })
            .then(response => {
                this.componentDidMount();
                toast('تم التعديل بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        if (this.state.spin) {
                        return (
                            <div >
                               
                                <div className='DataTableContiner'>
                                    <MuiThemeProvider

                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable data={this.state.Category} columns={columns} options={options} />
                                    </MuiThemeProvider>
                                </div>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        }
                        else {
                            return (
                                <div style={{ width: '100%', height: 'calc(100vh)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner/>
                                </div>
                            )
                        }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default fromOrders;