import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
// import qs from 'qs';
import PersonIcon from '@material-ui/icons/Person';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import Component from "@reactions/component";
import { Pane, Dialog, Table, Spinner } from 'evergreen-ui';
import VisibilityIcon from '@material-ui/icons/Visibility';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import AppBar from '../common/appBar';
import Login from '../common/login';
const columns = [
    { field: "items", name: "المواد", options: { width: 100, filter: true, sort: false, } },
    { field: "newPrice", name: "السعر بعد الخصم", options: { filter: true, sort: false, } },
    { field: "discount", name: "نسبه الخصم", options: { filter: true, sort: false, } },
    { field: "price", name: "السعر", options: { filter: true, sort: false, } },
    { field: "date", name: "تاريخ الطلب", options: { filter: true, sort: true, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            nameCate: '',
            Category: [],
            spin: false,
            spinerMod: false,
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })

    componentDidMount() {
        var name = localStorage.getItem('nameCustomer')
        var phone = localStorage.getItem('phoneCustomer')
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/customers/orders/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                let arr = [];
                console.log(res.data.data.data.orders);
                for (let i = 0; i < res.data.data.data.orders.length; i++) {
                    let obj = {
                        date: res.data.data.data.orders[i].createdAt,
                        price: res.data.data.data.orders[i].price,
                        discount: res.data.data.data.orders[i].discount,
                        newPrice: res.data.data.data.orders[i].new_price,
                        items: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        hasFooter={false}
                                        hasHeader={false}
                                    >
                                        <div style={{ direction: 'rtl', textAlign: 'right' }}>
                                            {this.state.spinerMod ? (<Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                                                <Spinner />
                                            </Pane>) : (

                                                    <Table>
                                                        <Table.Head>
                                                            <Table.TextHeaderCell >اسم المادة</Table.TextHeaderCell>
                                                            <Table.TextHeaderCell>العدد</Table.TextHeaderCell>
                                                            <Table.TextHeaderCell>صوره</Table.TextHeaderCell>
                                                        </Table.Head>
                                                        <Table.Body height={240}>
                                                            {res.data.data.data.orders[i].orderItems.map((item, i) =>
                                                                <Table.Row key={i} >
                                                                    <Table.TextCell>{item.item.name}</Table.TextCell>
                                                                    <Table.TextCell>{item.count}</Table.TextCell>
                                                                    <Table.TextCell>
                                                                        <img src={host + 'files/' + item.item.photo} alt='img' style={{ height: 40, width: 40, borderRadius: 5 }} />
                                                                    </Table.TextCell>
                                                                </Table.Row>
                                                            )}
                                                        </Table.Body>
                                                    </Table>
                                                )}
                                        </div>

                                    </Dialog>
                                    <VisibilityIcon style={{ cursor: 'pointer', color: '#77da5e' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                    };
                    arr.push(obj);
                }
                this.setState({
                    Category: arr, spin: true, name, phone
                });
            })
            .catch(error => { console.log(error.response) })

    }

    render() {
        if (cookies.get("token")) {
        return (
            <Context.Consumer>
                {ctx => {
                    // if (this.state.spin) {
                    return (
                        <div style={{direction:'rtl'}} >
                            <AppBar page='customerOrder' />
                            <div id='headerCustomerOrderContiner'>
                                <div>
                                    <PersonIcon /> {this.state.name}
                                </div>
                                <div>
                                    <PhoneInTalkIcon /> {this.state.phone}
                                </div>
                            </div>
                            <div className='DataTableContiner'>
                                <MuiThemeProvider
                                    theme={this.getMuiTheme()}>
                                    <MaterialDatatable data={this.state.Category} columns={columns} options={options} />
                                </MuiThemeProvider>
                            </div>
                        </div>
                    )
                    // }
                    // else {
                    //     return (
                    //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    //             <Lottie
                    //                 options={{
                    //                     animationData: animation
                    //                 }}
                    //             />
                    //         </div>
                    //     )
                    // }
                }
                }
            </Context.Consumer>
        )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;