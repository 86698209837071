import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";

import {  Button } from 'evergreen-ui';
import { ToastContainer } from 'react-toastify';
import SearchIcon from '@material-ui/icons/AttachFileOutlined';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import * as moment from "moment-timezone";
import Login from '../common/login';
import exportFromJSON from 'export-from-json'

// import { JsonToExcel } from "react-json-to-excel";


const columns = [


    { field: "text", name: "الحالة", options: { filter: true, sort: false, } },
    { field: "page", name: "اسم البيج", options: { filter: true, sort: true, } },
    { field: "items_count", name: "عدد القطع", options: { width: 75, filter: true, sort: true, } },

    { field: "price", name: "السعر", options: { width: 25, filter: true, sort: true, } },
    { field: "government", name: "العنوان", options: { width: 350, filter: true, sort: true, } },
    { field: "phone", name: "رقم الهاتف", options: { filter: true, sort: true, } },

    { field: "id", name: "رقم الوصل", options: { width: 150, filter: true, sort: false, } },
    //   { field: "i", name: "#", options: { filter: true, sort: false, } },

];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    print: false,
    sort: false,
    rowsPerPage: 300,
    search: false,
    filter: false,
    viewColumns: false,
    download: false,
    pagination: false,
    onSearchChange: (e) => {
    
    },
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();
let id = 0
class Selles_Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            total: '',
            total_items: 0,
            total_orders: 0,
            items_cost: 0,
            orders_cost: 0,
            ordes2: [],
            id: '',

        }
        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')
        let type = urlParams.get('type')
        this.setState({ id: Getid, type })
        this.Orders(Getid, type)
        id = Getid
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                    fontSize: '25px',
                    fontWeight: 'bold',
                    border: '2px solid'

                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '25px',
                    border: '2px solid'
                }
            },

        }
    })

    Orders(id, type) {

        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `users/printed/orders/get/${id}?type=1`, { headers: header })
            .then(res => {
                let arr = [];
                let items = res.data.data.orders
      
                let ordes2 = []
                for (let i = 0; i < items.length; i++) {
                    let obj = {
                        i: i + 1,

                        id: items[i].id,
                        phone: items[i].phone,
                        name: items[i].name,
                        government: items[i].government + "/" + items[i].city,
                        city: items[i].city,
                        price: items[i].price,
                        page: items[i].page,
                        items_count: items[i].items_count,
                        createdAt: items[i].createdAt,
                        text: ''
                    };
                    let price = items[i].price - items[i].shping_price
                    let obj2 = {
                       
                        'رقم الزبون': items[i].phone,
                        'المبلغ الصافي': price,
                        'المبلغ التوصيل': items[i].shping_price,
                        'المبلغ الكلي': items[i].price,
                        'المنطقة': items[i].city,
                        'المحافظة': items[i].government,
                        'رقم الوصل': items[i].id,

                        'اسم البيج': items[i].page,
                        'التاريخ': moment(new Date()).format('DD/MM/'),











                    };
                    arr.push(obj);
                    ordes2.push(obj2)
                }


                this.setState({
                    orders_count: items.length,
                    orders: arr,
                    spin: false,
                    total_items: res.data.data.items_sum,
                    total_orders: res.data.data.order_count,
                    items_cost: res.data.data.items_cost,
                    orders_cost: res.data.data.sells,
                    ordes2
                });
            })
            .catch(error => {
           
            });
    }
    handleChangeFrom = date => {
   

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    download() {
        const data = this.state.ordes2
        const fileName = `${moment(new Date()).format('YYYY-MM-DD')}/${id}`
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >

                                <div id='navDashContiner'>
                                    <Button marginRight={16} appearance="primary" intent="success"
                                        iconBefore={SearchIcon}
                                        onClick={() => {
                                            this.download()
                                        }}
                                        height={45}
                                    >
                                        تحميل
                                    </Button>
                                    {/* <button
                                        onClick={() => {
                                            this.download()
                                        }}
                                    ></button> */}
                                </div>


                                <br />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                    <div style={{ textAlign: 'left', marginLeft: '15px' }}>
                                        <h5 style={{ textAlign: 'right', marginBottom: '15px' }}> اسم المستلم </h5>
                                        <br />
                                        <h5 style={{ textAlign: 'right', marginBottom: '15px' }}> التوقيع </h5>
                                        <br />
                                    </div>
                                    <div style={{ textAlign: 'right', marginRight: '15px' }}>
                                        <h5>رقم القائمة: {id}</h5>

                                        <h5>التاريخ: {moment(new Date()).format('DD/MM/YYYY')}</h5>
                                        <h5> عدد الطلبات :{this.state.orders_count} </h5>


                                    </div>
                                </div>


                                <div>
                                    <div className='DataTableContiner'>
                                        <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.orders} columns={columns} options={options} />
                                        </MuiThemeProvider>
                                    </div>

                                </div>

                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Selles_Reports;