import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from '../common/login';
import {  Row, Col, Container } from 'react-bootstrap';
import DatePicker from "react-datepicker";

import { Link } from 'react-router-dom';

const cookies = new Cookies();


class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            Category: [],
            spin: false,
            from: new Date(),
            to: new Date(),
            caneclitems: 0,
            caneclorder: 0,
            doneitems: 0,
            doneorder: 0,
            rejecteditems: 0,
            rejectedorder: 0,
            wattingOrders: 0,
            wattingitems: 0,
            allOrders: 0,
            alItems: 0,
            printOrders: 0,
            printItems: 0
        }
    }
    handleChangeFrom = date => {

    
        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    Orders() {
        const queryString2 = window.location.search;
        const urlParams2 = new URLSearchParams(queryString2);
        const id2 = urlParams2.get('id')
        
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };

        axios.get(host + `dashbord/pgaeById?from=${this.state.from}&to=${this.state.to}&id=${id2}`, { headers: header })
            .then(res => {

                this.setState({
                    caneclitems: res.data.orders.caneclitems,
                    caneclorder: res.data.orders.caneclorder,
                    doneitems: res.data.orders.doneitems,
                    doneorder: res.data.orders.doneorder,
                    rejecteditems: res.data.orders.rejecteditems,
                    rejectedorder: res.data.orders.rejectedorder,
                    wattingOrders: res.data.orders.wattingOrders,
                    wattingitems: res.data.orders.wattingitems,
                    allOrders: res.data.orders.allOrders,
                    alItems: res.data.orders.alItems,
                    printOrders: res.data.orders.printOrders,
                    printItems: res.data.orders.printItems

                });
            })
            .catch(error => { console.log(error.response) })

    }

    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='navDashContiner'>
                                    {/* <Component initialState={{ isShown: false, nameUser: '', phoneUser: '', passwordUser: '', }}>
                                        {({ state, setState }) => (
                                            <React.Fragment>
                                                <SideSheet
                                                    isShown={state.isShown}
                                                    position={Position.LEFT}
                                                    onCloseComplete={() => setState({ isShown: false })}
                                                >
                                                    <div margin={40}>
                                                        <div id='titleAddUser' style={{ backgroundColor: '#000' }}>اضافة موظف</div>
                                                        <div id='inputAdd2Continer' >
                                                            <div id={'ContinerInPut'} >
                                                                <label>اسم الموظف</label>
                                                                <input autoComplete='off' type='text' placeholder='اسم الموظف' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ nameUser: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>رقم الهاتف</label>
                                                                <input autoComplete='off' type='number' placeholder='رقم الهاتف' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ phoneUser: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>كلمه المرور</label>
                                                                <input autoComplete='off' type='text' placeholder='كلمه المرور' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ passwordUser: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div id='btnAddContiner'>
                                                            <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {
                                                                axios.post(host + `users/auth/register`,
                                                                    qs.stringify({ name: state.nameUser, password: state.passwordUser, phone: state.phoneUser }), {
                                                                    headers: {
                                                                        "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        toast('تمت الاضافة بنجاح', {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });

                                                                    })
                                                                    .catch(error => {
                                                                        console.log(error.response.data.message)
                                                                        this.setState({ spinerBtn: false })
                                                                    })
                                                                setState({ isShown: false })
                                                            }}>اضافة</div>
                                                        </div>

                                                    </div>
                                                </SideSheet>
                                                <div id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })}>اضافة موظف</div>
                                            </React.Fragment>
                                        )}
                                    </Component> */}
                                    <Link to={'/pages'} id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} > رجوع</Link>
                                </div>
                                <br></br>
                                <div id='ContinerReportDate' style={{direction:"rtl"}}>
                                    <Row id='RowReportDate' style={{ paddingTop: 20 }}>
                                        <Col id='ColReportDate' sm={12} md={4}>
                                        <span style={{ marginLeft: "10px" }}>  من : &nbsp;</span>
                                            <div> <DatePicker id='date1'
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.from}
                                                onChange={this.handleChangeFrom}
                                            />
                                            </div>
                                           
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={4}>
                                        <span style={{ marginLeft: "10px" }}>الى : &nbsp;</span>
                                         <div>
                                         <DatePicker id='date1'
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.to}
                                                onChange={this.handleChangeTo}
                                            />
                                         </div>
                                         
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={4}>
                                        <div id='AddClientDeptBTN' onClick={() => {
                                                this.setState({ spin: true, serch: true })
                                                this.Orders()
                                            }}>بحث</div>
                                        </Col>
                                    </Row>
                                </div>
                              
                                <Container>

                                    <Row className="justify-content-md-center">
                                        <Col lg="2" style={{ borderBottom: "solid" }}>

                                        </Col>
                                        <Col md="auto" style={{ textAlign: "center" }}> الطلبات الكلية</Col>
                                        <Col lg="2" style={{ borderBottom: "solid" }}>

                                        </Col>
                                    </Row>
                                    <br />
                                    <Row className="justify-content-md-center">
                                        <Col >
                                            <div>

                                                <div id='ContinerReportDate1' style={{ flexDirection: 'row-reverse' }}>
                                                    <div id='AddClientDeptBTN1'>عدد القطع الكلي</div>
                                                    <div style={{ marginRight: 20, flexDirection: 'row-reverse' }}>{this.state.alItems}</div>

                                                </div>


                                            </div>
                                        </Col>
                                        <Col >
                                            <div>

                                                <div id='ContinerReportDate1' style={{ flexDirection: 'row-reverse' }}>
                                                    <div id='AddClientDeptBTN1'>مجموع الطلبات الكلي</div>
                                                    <div style={{ marginRight: 20, flexDirection: 'row-reverse' }}>{this.state.allOrders}</div>

                                                </div>


                                            </div>
                                        </Col>

                                    </Row>
                                    <br />
                                    <Row className="justify-content-md-center">
                                        <Col xs lg="2" style={{ borderBottom: "solid" }}>

                                        </Col>
                                        <Col md="auto" style={{ textAlign: "center" }}> الطلبات قبل الطباعة</Col>
                                        <Col xs lg="2" style={{ borderBottom: "solid" }}>

                                        </Col>
                                    </Row>
                                    <br />
                                    <Row className="justify-content-md-center">
                                        <Col >
                                            <div>

                                                <div id='ContinerReportDate1' style={{ flexDirection: 'row-reverse' }}>
                                                    <div id='AddClientDeptBTN1' style={{ backgroundColor: '#aa09c1' }}>عدد القطع قبل الطباعة</div>
                                                    <div style={{ marginRight: 20, flexDirection: 'row-reverse' }}>{this.state.printItems}</div>

                                                </div>


                                            </div>
                                        </Col>
                                        <Col >
                                            <div>

                                                <div id='ContinerReportDate1' style={{ flexDirection: 'row-reverse' }}>
                                                    <div id='AddClientDeptBTN1' style={{ backgroundColor: '#aa09c1' }}>مجموع الطلبات قبل الطباعة</div>
                                                    <div style={{ marginRight: 20, flexDirection: 'row-reverse' }}>{this.state.printOrders}</div>

                                                </div>


                                            </div>
                                        </Col>

                                    </Row>
                                    <br />
                                    <Row className="justify-content-md-center">
                                        <Col xs lg="2" style={{ borderBottom: "solid" }}>

                                        </Col>
                                        <Col md="auto" style={{ textAlign: "center" }}> الطلبات المعلقة</Col>
                                        <Col xs lg="2" style={{ borderBottom: "solid" }}>

                                        </Col>
                                    </Row>
                                    <br />
                                    <Row className="justify-content-md-center">
                                        <Col >
                                            <div>

                                                <div id='ContinerReportDate1' style={{ flexDirection: 'row-reverse' }}>
                                                    <div id='AddClientDeptBTN1' style={{ backgroundColor: "#c6cf1b" }} >عدد القطع المعلقة</div>
                                                    <div style={{ marginRight: 20, flexDirection: 'row-reverse' }}>{this.state.wattingitems}</div>

                                                </div>


                                            </div>
                                        </Col>
                                        <Col >
                                            <div>

                                                <div id='ContinerReportDate1' style={{ flexDirection: 'row-reverse' }}>
                                                    <div id='AddClientDeptBTN1' style={{ backgroundColor: "#c6cf1b" }}>مجموع الطلبات المعلقة</div>
                                                    <div style={{ marginRight: 20, flexDirection: 'row-reverse' }}>{this.state.wattingOrders}</div>

                                                </div>


                                            </div>
                                        </Col>

                                    </Row>


                                    <Row className="justify-content-md-center">
                                        <Col xs lg="2" style={{ borderBottom: "solid" }}>

                                        </Col>
                                        <Col md="auto" style={{ textAlign: "center" }}> الطلبات الناجح</Col>
                                        <Col xs lg="2" style={{ borderBottom: "solid" }}>

                                        </Col>
                                    </Row>
                                    <br />
                                    <Row className="justify-content-md-center">
                                        <Col >
                                            <div>

                                                <div id='ContinerReportDate1' style={{ flexDirection: 'row-reverse' }}>
                                                    <div id='AddClientDeptBTN1' style={{ backgroundColor: "#1bcf2b" }}>عدد القطع الناجح</div>
                                                    <div style={{ marginRight: 20, flexDirection: 'row-reverse' }}>{this.state.doneitems}</div>

                                                </div>


                                            </div>
                                        </Col>
                                        <Col >
                                            <div>

                                                <div id='ContinerReportDate1' style={{ flexDirection: 'row-reverse' }}>
                                                    <div id='AddClientDeptBTN1' style={{ backgroundColor: "#1bcf2b" }}>مجموع الطلبات الناجح</div>
                                                    <div style={{ marginRight: 20, flexDirection: 'row-reverse' }}>{this.state.doneorder}</div>

                                                </div>


                                            </div>
                                        </Col>

                                    </Row>



                                    <Row className="justify-content-md-center">
                                        <Col xs lg="2" style={{ borderBottom: "solid" }}>

                                        </Col>
                                        <Col md="auto" style={{ textAlign: "center" }}> الطلبات الراجع</Col>
                                        <Col xs lg="2" style={{ borderBottom: "solid" }}>

                                        </Col>
                                    </Row>
                                    <br />
                                    <Row className="justify-content-md-center">
                                        <Col >
                                            <div>

                                                <div id='ContinerReportDate1' style={{ flexDirection: 'row-reverse' }}>
                                                    <div id='AddClientDeptBTN1' style={{ backgroundColor: "#c93f1b" }}>عدد القطع الراجع</div>
                                                    <div style={{ marginRight: 20, flexDirection: 'row-reverse' }}>{this.state.rejecteditems}</div>

                                                </div>


                                            </div>
                                        </Col>
                                        <Col >
                                            <div>

                                                <div id='ContinerReportDate1' style={{ flexDirection: 'row-reverse' }}>
                                                    <div id='AddClientDeptBTN1' style={{ backgroundColor: "#c93f1b" }}>مجموع الطلبات الراجع</div>
                                                    <div style={{ marginRight: 20, flexDirection: 'row-reverse' }}>{this.state.rejectedorder}</div>

                                                </div>


                                            </div>
                                        </Col>

                                    </Row>


                                    <Row className="justify-content-md-center">
                                        <Col xs lg="2" style={{ borderBottom: "solid" }}>

                                        </Col>
                                        <Col md="auto" style={{ textAlign: "center" }}> الطلبات الملغية</Col>
                                        <Col xs lg="2" style={{ borderBottom: "solid" }}>

                                        </Col>
                                    </Row>
                                    <br />
                                    <Row className="justify-content-md-center">
                                        <Col >
                                            <div>

                                                <div id='ContinerReportDate1' style={{ flexDirection: 'row-reverse' }}>
                                                    <div id='AddClientDeptBTN1' style={{ backgroundColor: "#320b01" }}>عدد القطع الملغي</div>
                                                    <div style={{ marginRight: 20, flexDirection: 'row-reverse' }}>{this.state.caneclitems}</div>

                                                </div>


                                            </div>
                                        </Col>
                                        <Col >
                                            <div>

                                                <div id='ContinerReportDate1' style={{ flexDirection: 'row-reverse' }}>
                                                    <div id='AddClientDeptBTN1' style={{ backgroundColor: "#320b01" }}>مجموع الطلبات الملغي</div>
                                                    <div style={{ marginRight: 20, flexDirection: 'row-reverse' }}>{this.state.caneclorder}</div>

                                                </div>


                                            </div>
                                        </Col>

                                    </Row>
                                </Container>



                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;