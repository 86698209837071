import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';

import {  Icon, Combobox } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import "@kenshooui/react-multi-select/dist/style.css"
import { Row, Col, Alert, Card, InputGroup, FormControl, Form, Image, Button, Container } from 'react-bootstrap';




// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';

import Login from '../common/login';
import * as moment from "moment-timezone";


const cookies = new Cookies();
const items = [{ label: 'بغداد' }, { label: 'اربيل' },
{ label: 'بابل' }, { label: 'الأنبار' }, { label: 'القادسية' }, { label: 'ميسان' },
{ label: 'دهوك' }, { label: 'السلميانية' }, { label: 'نينوى' }
    , { label: 'واسط' }, { label: 'ديالى' }, { label: 'النجف' },
{ label: 'صلاح الدين' }, { label: 'كركوك' }, { label: 'كربلاء' },
{ label: 'المثنى' }, { label: 'كركوك' }, { label: 'كربلاء' }]
class fromOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 1,
            name: '',
            phone: '',
            government: '',
            city: "",
            notes: "sdsdd",
            price: 0,
            user_name: "",
            items: [],
            createdAt: "",
            nameCate: '',
            Category: [],
            spin: false,
            totalCount: '',
            nameItem: '',
            priceItem: '',
            image: [],
        }
    }


    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/form/orders/all?id=22`, { headers: header })
            .then(res => {
                let arr = [];
         
                let data = res.data.data.orders[0];
         
                this.setState({
                    id: data.id,
                    name: data.name,
                    phone: data.phone,
                    city: data.city,
                    government: data.government,
                    price: data.price,
                    notes: data.notes,
                    createdAt: moment(data.createdAt).tz('Asia/baghdad').format('YYYY/MM/DD'),
                    user_name: data.user.name,
                    items: data.items
                })

                this.setState({
                    Category: arr, spin: true
                });
            })
            .catch(error => { console.log(error.response) })

    }
    DeleteCate(id) {

        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `users/all/ordrers/${id}`, { headers: header })
            .then(response => {

                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    EditItem(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", this.state.nameItem);
        formData.append("price", this.state.priceItem);
        formData.append("category_id", this.props.match.params.id);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `dashbord/item/${id}`, method: "PUT", data: formData, headers: header })
            .then(response => {
                this.componentDidMount();
                toast('تم التعديل بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    AddItem() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", this.state.nameItem);
        formData.append("count", 1);
        formData.append("price", this.state.priceItem);
        formData.append("hasCount", false);
        formData.append("category_id", this.props.match.params.id);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `dashbord/item`, method: "POST", data: formData, headers: header })
            .then(response => {
                this.setState({ spinerBtn: false })
                this.componentDidMount();
                toast('تم اضافة المادة بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    onChangeD(value, item, text) {
        let count = document.getElementById(value).value;
        if (count >0) {
            let newValue = Number(document.getElementById(value).value) - 1;
            document.getElementById(value).value = newValue
            // let re = ((item.count - count + 1) + 1)
            // console.log(re);
            // document.getElementById(text).textContent = `الكمية المتبقية ${re}`

        }

    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='navDashContiner'>
                                    <Link to='/cate1'>
                                        <Icon icon="circle-arrow-left" size={30} color="#fff"
                                            style={{ cursor: 'pointer' }} />
                                    </Link>

                                </div>
                                <br />
                                <center>
                                    <Row className="justify-content-md-center">
                                        <Alert style={{ fontSize: '24px', fontWeight: 'bold' }} variant={'primary'}>
                                            معلومات الطلب رقم {this.state.id}
                                        </Alert>
                                    </Row>                    </center>
                                <Row className="justify-content-md-center">
                                    <Col xs lg="2">
                                        <InputGroup size="lg" dir="rtl">
                                            <InputGroup.Text id="inputGroup-sizing-lg">هاتف الزبون</InputGroup.Text>
                                            <FormControl aria-label="Large" type="number" aria-describedby="inputGroup-sizing-sm" value={this.state.phone} onChange={(e) => {
                                                this.setState({ phone: e.target.value })
                                            }} />
                                        </InputGroup>
                                    </Col>
                                    <Col md="auto">
                                        <InputGroup size="lg" dir="rtl">
                                            <InputGroup.Text id="inputGroup-sizing-lg">اسم الزبون</InputGroup.Text>
                                            <FormControl aria-label="Large" aria-describedby="inputGroup-sizing-sm" value={this.state.name} onChange={(e) => {
                                                this.setState({ name: e.target.value })
                                            }} />
                                        </InputGroup>

                                    </Col>
                                    <Col xs lg="2">
                                        <InputGroup size="lg" dir="rtl">
                                            <InputGroup.Text id="inputGroup-sizing-lg">رقم الوصل</InputGroup.Text>
                                            <FormControl aria-label="Large" aria-describedby="inputGroup-sizing-sm" value={this.state.id} disabled />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <br />
                                <Row className="justify-content-md-center">
                                    <Col xs lg="2">
                                        <InputGroup size="lg" dir="rtl">
                                            <InputGroup.Text id="inputGroup-sizing-lg">السعر مع التوصيل </InputGroup.Text>
                                            <FormControl aria-label="Large" type="number" aria-describedby="inputGroup-sizing-sm" value={this.state.price} onChange={(e) => { this.setState({ price: e.target.value }) }} />
                                        </InputGroup>
                                    </Col>
                                    <Col md="auto">
                                        <InputGroup size="lg" dir="rtl">
                                            <InputGroup.Text id="inputGroup-sizing-lg">المنقطة</InputGroup.Text>
                                            <FormControl aria-label="Large" aria-describedby="inputGroup-sizing-sm" value={this.state.city} onChange={(e) => {
                                                this.setState({ city: e.target.value })
                                            }} />
                                        </InputGroup>

                                    </Col>
                                    <Col xs lg="2">

                                        <Combobox
                                            width="100%"
                                            id={'Comboboxgovernment'}
                                            height={50}
                                            initialSelectedItem={{ label: this.state.government }}
                                            items={items}
                                            itemToString={item => (item ? item.label : '')}
                                            onChange={selected => this.setState({ government: selected.label })}
                                        />

                                    </Col>
                                </Row>
                                <br />
                                <Row className="justify-content-md-center">

                                    <Col xs lg="2">
                                        <InputGroup size="lg" dir="rtl">
                                            <InputGroup.Text id="inputGroup-sizing-lg">تاريخ الطلب</InputGroup.Text>
                                            <FormControl aria-label="Large" aria-describedby="inputGroup-sizing-sm" value={this.state.createdAt} disabled />
                                        </InputGroup>
                                    </Col>
                                    <Col md="auto">
                                        <InputGroup size="lg" dir="rtl">
                                            <InputGroup.Text id="inputGroup-sizing-lg">اسم البيج</InputGroup.Text>
                                            <FormControl aria-label="Large" aria-describedby="inputGroup-sizing-sm" value={this.state.user_name} disabled />
                                        </InputGroup>

                                    </Col>

                                </Row>
                                <br />
                                <Row className="justify-content-md-center">
                                    <Col xs lg="6">
                                        <InputGroup size="lg" dir="rtl">
                                            <InputGroup.Text id="inputGroup-sizing-lg">ملاحظات</InputGroup.Text>
                                            <FormControl aria-label="Large" aria-describedby="inputGroup-sizing-sm" vale={this.state.notes}
                                                value={this.state.notes}
                                                onChange={(e) => {
                                                    this.setState({ notes: e.target.value })
                                                }} />

                                        </InputGroup>
                                    </Col>
                                </Row>
                                <hr />
                                <center>
                                    <Row className="justify-content-md-center">
                                        <Alert style={{ fontSize: '24px', fontWeight: 'bold' }} variant={'success '}>
                                            مواد الطلب
                                        </Alert>
                                    </Row>                    </center>
                                <hr />
                                <Row >
                                    <Col md={{ span: 5, offset: 3 }}>
                                        <center>
                                            <h4 style={{ margin: "20px" }}> المتنجات اللتي تم اختيارها</h4></center>
                                        <Row className="justify-content-md-center">
                                            {this.state.items.map((item, i) => (
                                                <Col xs={6} md={4} style={{  margin: '20px'}} key={i}>
                                                    <div>
                                                        <Card style={{ width: '300px' }}>

                                                            <Card.Body>
                                                                <center><b>{item.name}</b></center>
                                                                <br></br>
                                                                <Row >
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <Image style={{ width: '100px', height: '100px' }} src="https://www.dhresource.com/0x0/f2/albu/g16/M01/66/68/rBVa32AE8WKAblemAAMk9yjY9Jk361.jpg" rounded />
                                                                        <div style={{ display: 'flex', marginLeft: '20px', marginBottom: '20px' }}>
                                                                            <Button style={{ width: '50px', height: '50px' }} variant="danger" onClick={() => {
                                                                                this.onChangeD(item.name, item, `${i}count`)
                                                                            }}><b>-</b></Button>
                                                                            <Form.Control style={{ width: '50px', height: '50px', fontWeight: 'bold', textAlign: 'center' }} value={item.count}
                                                                                    id={item.name}
                                                                                isabled />
                                                                            <Button style={{ width: '50px', height: '50px' }} variant="success" disabled onClick={() => {
                                                                                //   console.log(this.state.selectedItems);
                                                                                // this.onChangeI(item.name, item, `${i}count`)

                                                                            }}><b>+</b></Button>

                                                                        </div>

                                                                    </div>
                                                                    <center>
                                                                        <Form.Text
                                                                        // id={`${i}count`}
                                                                        >
                                                                            {/* {`  الكمية المتبقية ${item.count}`} */}
                                                                        </Form.Text>
                                                                    </center>
                                                                </Row>
                                                                <hr></hr>
                                                            </Card.Body>
                                                        </Card>
                                                    </div>
                                                </Col>

                                            ))}

                                        </Row>

                                    </Col>
                                </Row>






                                <Container>

                                </Container>
                                {/* <div className='DataTableContiner'>
                                    <MuiThemeProvider
                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable data={this.state.Category} columns={columns} options={options} />
                                    </MuiThemeProvider>
                                </div> */}
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default fromOrders;