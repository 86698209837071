
import React from 'react';
import Context from '../../assets/js/Context';
import Component from "@reactions/component";
import { Table } from 'react-bootstrap';
import Cookies from "universal-cookie";
import { Spinner, FilePicker } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import host from '../../assets/js/Host';
import axios from 'axios';
import qs from 'qs';
import Login from '../common/login';
const cookies = new Cookies();

class Setting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ID: '',
            Drivers: [],
            UserId: '',
            DriverId: '',
            num: '',
            nameClient: '',
            phone1Client: '',
            government: '',
            city: '',
            address: '',
            price: '',
            sprice: '',
            note: '',
            spin: false,
            NameClient: '',
            spinAdd: true,
            B2: false,
            ChengePrice: false,
            BackO: false,
            Cash: false,
            spinPage: true,
            nameIT: '',
            SpriceIT: '',
            PpriceIT: '',
            BarcodeIT: '',
            category_id: '',
            files: [],
        }
    }
    componentDidMount() {
        var nameIT = localStorage.getItem('nameIT');
        var SpriceIT = localStorage.getItem('SpriceIT');
        var PpriceIT = localStorage.getItem('PpriceIT');
        var BarcodeIT = localStorage.getItem('BarcodeIT');
        var category_id = localStorage.getItem('category_id');
        this.setState({
            nameIT, SpriceIT, PpriceIT, BarcodeIT, category_id, spinPage: false,
        });

    }


    CityFun() {
        var arr = [{ value: 'المحافظة', label: 'المحافظة' }];
        var city = this.state.gover;
        for (let i = 0; i < city.length; i++) {
            arr.push(
                { value: city[i].name, label: city[i].name }
            )
        }
        return arr
    }

    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    if (!this.state.spinPage) {
                        return (
                            <div style={{ width: '100%', height: '100vh', overflowY: 'scroll', direction: 'rtl' }}>
                                <div id='AppBarPageContiner' style={{ width: '100%', display: 'flex', justifyContent: 'space-between', height: 60, backgroundColor: '#AEB6BF' }}>

                                    <h6>{this.state.NameClient}</h6>

                                </div>
                                <div id='contentSeettingContiner'>

                                    <Table striped bordered hover>
                                        <tbody>

                                            <tr style={{ textAlign: 'center', border: '2px soild #000' }}>
                                                <td>
                                                    <div id='InputAddOrderContiner'>
                                                        <div id='InputAddOrderLabel'>اسم الماده</div>
                                                        <input type='text' id='InputAddOrder' placeholder='اسم الماده'
                                                            value={this.state.nameIT} onChange={(e) => this.setState({ nameIT: e.target.value })} />
                                                    </div>
                                                </td>

                                                <td>
                                                    <div id='InputAddOrderContiner'>
                                                        <div id='InputAddOrderLabel'>سعر الشراء</div>
                                                        <input type='number' id='InputAddOrder' placeholder='سعر الشراء'
                                                            value={this.state.SpriceIT} onChange={(e) => this.setState({ SpriceIT: e.target.value })} />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div id='InputAddOrderContiner'>
                                                        <div id='InputAddOrderLabel'>سعر البيع</div>
                                                        <input type='number' id='InputAddOrder' placeholder='سعر البيع'
                                                            value={this.state.PpriceIT} onChange={(e) => this.setState({ PpriceIT: e.target.value })} />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr style={{ textAlign: 'center', border: '2px soild #000' }}>

                                                <td>
                                                    <div id='InputAddOrderContiner'>
                                                        <div id='InputAddOrderLabel'>الباركود</div>
                                                        <input type='number' id='InputAddOrder' placeholder='الباركود'
                                                            value={this.state.BarcodeIT} onChange={(e) => this.setState({ BarcodeIT: e.target.value })} />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div id='InputAddOrderContiner'>
                                                        <div id='InputAddOrderLabel'>صوره</div>
                                                        <FilePicker
                                                            id='InputAddOrder'
                                                            multiple
                                                            width={250}
                                                            onChange={files => this.setState({ files })}
                                                            placeholder="اختيار صوره"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div id='InputAddOrderContiner'>
                                                        {!this.state.spin ?
                                                            <div id='AddOrderBTn' onClick={() => {

                                                                this.setState({ spin: true })
                                                                var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
                                                                let formData = new FormData();
                                                                formData.append("name", this.state.nameIT);
                                                                formData.append("price", this.state.PpriceIT);
                                                                formData.append("dw_price", this.state.SpriceIT);
                                                                formData.append("barcode", this.state.BarcodeIT);
                                                                formData.append("category_id", this.state.category_id);
                                                                formData.append("file", this.state.files[0]);
                                                                axios({ url: host + `dashbord/component/edit/${this.props.match.params.id}`, method: "PUT", data: formData, headers: header })
                                                                    .then(response => {
                                                                       window.close()
                                                                        toast('تم التعديل بنجاح', {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });
                                                                    })
                                                                    .catch(error => { console.log(error.response.data.message) })
                                                            }}>تعديل</div>
                                                            : <div id='AddOrderBTn'><Spinner /></div>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                </div>


                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                    }
                    else {
                        return (
                            <div style={{ width: '100%', height: 'calc(100vh)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Spinner />
                            </div>
                        )
                    }
                }
                }

            </Context.Consumer >
        )
    }
}

export default Setting;