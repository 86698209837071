import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import qs from 'qs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from '../common/login';
import { Button, Row, Col, Container, InputGroup, Form } from 'react-bootstrap';

import { Link } from 'react-router-dom';

const cookies = new Cookies();



class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            Category: [],
            spin: false,
            name: "",
            baghad: "",
            others: "",
            coypes: "",


        }
    }
    handleChangeFrom = date => {


        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };

        axios.get(host + `dashbord/setting/all`, { headers: header })
            .then(res => {
                let data = res.data.setting
                let name = data.find((i) => i.name === "توصيل").value
                let baghad = data.find((i) => i.name === "بغداد").value
                let others = data.find((i) => i.name === "محافظات").value
                let coypes = data.find((i) => i.name === "النسخ").value


                this.setState({
                    name: name,
                    baghad: baghad,
                    others: others,
                    coypes: coypes,

                });
            })
            .catch(error => { console.log(error.response) })

    }

    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='navDashContiner'>
                                    <Link to={'/SettingDash'} id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} > رجوع</Link>
                                </div>
                                <br></br>
                                <Container>
                                    <Row className="justify-content-md-center">
                                        <Col>
                                            <div>
                                                <InputGroup className="mb-2" dir="rtl">
                                                    <InputGroup.Text id="basic-addon1">شركة التوصيل</InputGroup.Text>
                                                    <Form.Control
                                                        placeholder="اسم شركة التوصيل"
                                                        aria-label="Username"
                                                        value={this.state.name}
                                                        id="name"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                name: e.target.value
                                                            })
                                                        }}
                                                        aria-describedby="basic-addon1"
                                                    />

                                                </InputGroup>
                                                <br></br>
                                                <InputGroup className="mb-2" dir="rtl">
                                                    <InputGroup.Text id="basic-addon1">سعر توصيل بغداد </InputGroup.Text>
                                                    <Form.Control
                                                   
                                                        placeholder="سعر توصيل بغداد"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        value={this.state.baghad}
                                                        id="baghad"
                                                        type='number'
                                                        onChange={(e) => {
                                                            this.setState({
                                                                baghad: e.target.value
                                                            })
                                                        }}
                                                    />

                                                </InputGroup>
                                                <br></br>
                                                <InputGroup className="mb-2" dir="rtl">
                                                    <InputGroup.Text id="basic-addon1">سعر توصيل المحافظات </InputGroup.Text>
                                                    <Form.Control
                                                      
                                                        placeholder="سعر توصيل المحافظات"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        id="others"
                                                        type='number'
                                                        value={this.state.others}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                others: e.target.value
                                                            })
                                                        }}
                                                    />

                                                </InputGroup>

                                                <br></br>
                                                <InputGroup className="mb-2" dir="rtl">
                                                    <InputGroup.Text id="basic-addon1">  عدد نسخ الوصل </InputGroup.Text>
                                                    <Form.Control
                                                     
                                                        placeholder="  عدد نسخ الوصل "
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        type='number'
                                                        id="coypes"
                                                        value={this.state.coypes}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                coypes: e.target.value
                                                            })
                                                        }}
                                                    />

                                                </InputGroup>
                                            </div>

                                            <Button onClick={() => {
                                                let name = document.getElementById('name').value;
                                                console.log(name);
                                                let baghad = document.getElementById('baghad').value;
                                                console.log(baghad);
                                                let others = document.getElementById('others').value;
                                                console.log(others);
                                              
                                                let coypes = document.getElementById('coypes').value;
                                                console.log(coypes);

                                                
                                                axios.post(host + `dashbord/setting/edit`,
                                                    qs.stringify({ name, baghad, others, coypes }), {
                                                    headers: {
                                                        "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                    }
                                                })
                                                    .then(response => {
                                                        toast("تم التعديل بنجاح", {
                                                            position: "bottom-center",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true
                                                        });

                                                    })
                                                    .catch(error => {
                                                        console.log(error.response.data.message)
                                                        this.setState({ spinerBtn: false })
                                                    })

                                            }}>حفظ</Button>


                                        </Col>
                                    </Row>
                                </Container>



                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;