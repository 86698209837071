import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import Component from "@reactions/component";
import { Pane, Dialog, Spinner, SideSheet, Position, Icon } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form, Image, Button } from 'react-bootstrap';

// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import DoneIcon from '@material-ui/icons/DoneAll';
import Clear from '@material-ui/icons/Clear';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MoneyOff from '@material-ui/icons/MoneyOff';
import exportFromJSON from 'export-from-json'


import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Login from '../common/login';
import * as moment from "moment-timezone";
const columns = [

    { field: "createdAt", name: "تاريخ", options: { filter: true, sort: false, search: false } },
    { field: "days", name: "عدد الايام", options: { filter: true, sort: false, search: false } },
    { field: "price", name: "السعر", options: { filter: true, sort: false, search: false } },
    { field: "list", name: "رقم القائمة", options: { filter: true, sort: true, search: false } },


    { field: "government", name: "المحافظة", options: { filter: false, sort: false, search: false } },
    { field: "phone", name: "هاتف الزبون", options: { filter: false, search: false } },

    { field: "id", name: "#", options: { filter: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,

    print: true,
    filter: false,
    download: false,

    search: true,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",

        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    },

}
const cookies = new Cookies();

class fromOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            Category: [],
            spin: false,
            totalCount: '',
            nameItem: '',
            priceItem: '',
            image: [],
            data: []
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },
            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })
    onChangeD(value) {
        let count = document.getElementById(value).value;
        if (count > 0) {
            let newValue = Number(document.getElementById(value).value) - 1;
            document.getElementById(value).value = newValue
            // let re = ((item.count - count + 1) + 1)
            // console.log(re);
            // document.getElementById(text).textContent = `الكمية المتبقية ${re}`

        }

    }
    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/fromorders/apandind/orders`, { headers: header })
            .then(res => {
                let arr = [];
                let data = []
             
                this.setState({ data: res.data.data.orders })
                for (let i = 0; i < res.data.data.orders.length; i++) {

                    let obj = {
                        id: res.data.data.orders[i].id,
                        list: '',


                        phone: res.data.data.orders[i].phone,
                        government: res.data.data.orders[i].government,

                        price: res.data.data.orders[i].price,
                        days: moment(moment(res.data.data.orders[i].createdAt).tz('Asia/baghdad').format('YYYY,MM,DD')).fromNow(),

                        createdAt: moment(res.data.data.orders[i].createdAt).tz('Asia/baghdad').format('YYYY/MM/DD'),

                    };
                    let obj2 = {
                        "رقم الوصل": res.data.data.orders[i].id,


                        'رقم الهاتف': res.data.data.orders[i].phone,
                        المحافظة: res.data.data.orders[i].government,
                        'رقم القائمة': "",

                        السعر: res.data.data.orders[i].price,


                        التاريخ: moment(res.data.data.orders[i].createdAt).tz('Asia/baghdad').format('YYYY/MM/DD'),

                    };
                 
                    if (res.data.data.orders[i].printerOrders.length) {
                    
                        obj.list = res.data.data.orders[i].printerOrders[0].prints.id;
                        obj2['رقم القائمة'] = res.data.data.orders[i].printerOrders[0].prints.id

                    }
                    data.push(obj2)
                    arr.push(obj);
                }
                this.setState({
                    Category: arr, spin: true, data
                });
            })
            .catch(error => { console.log(error.response) })

    }

    download() {
        const data = this.state.data
        const fileName = `${moment(new Date()).format('YYYY-MM-DD')}`
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }

    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        if (this.state.spin) {
                            return (
                                <div >
                                    <br />
                                    <div id="downloadDiv">

                                        <button onClick={(e) => {
                                            this.download()

                                        }} id="serchbtn">تحميل</button>
                                    </div>
                                    <div className='DataTableContiner'>
                                        <MuiThemeProvider

                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.Category} columns={columns} options={options} />
                                        </MuiThemeProvider>
                                    </div>
                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ width: '100%', height: 'calc(100vh)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default fromOrders;